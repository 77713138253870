<template>
  <a-table
    :columns="columns"
    :data-source="trainedTasks"
    :loading="isTasksLoading"
    bordered
    :scroll="{ y: '49vh' }"
    :pagination="pagination"
    @change="handleTableChange"
  >
    <template #title>
      <a-typography-title :level="5">
        Trained Operation: &nbsp;
        <a-tag color="blue">{{ trainedTasks.length }}</a-tag>
      </a-typography-title>
    </template>
  </a-table>
</template>
<script>
import httpClient from '../../../../service/httpClient';
import dateHelper from '../../../shared/Helpers/dateHelper';
import trainingStatuses from 'src/config/training-status-config.js';

export default {
  data() {
    return {
      columns: [
        {
          title: 'Operation Name',
          dataIndex: 'taskName',
        },
        {
          title: 'Organization',
          dataIndex: 'Organization',
        },
        {
          title: 'Start Time',
          dataIndex: 'startTrainingTime',
        },
      ],
      trainedTasks: [],
      isTasksLoading: false,
      currentPage: 1,
      totalResult: 0,
    };
  },

  created() {
    this.getTrainingList();
  },
  computed: {
    pagination() {
      return {
        total: this.totalResult,
        current: this.currentPage,
        showSizeChanger: false,
        position: ['bottomCenter'],
      };
    },
  },
  methods: {
    handleTableChange(pag) {
      const { current } = pag;
      this.currentPage = current;
      this.getTrainingList();
    },
    async getTrainingList() {
      this.isTasksLoading = true;
      const res = await httpClient.getData(
        `organization/trained_tasks?train_status=${trainingStatuses.trained}&page=${this.currentPage}`,
        false
      );
      this.isTasksLoading = false;
      this.totalResult = res.count;
      this.trainedTasks = res.data.map((el) => ({
        ...el,
        startTrainingTime: dateHelper.formatDate(el.startTrainingTime),
        Organization: el?.Organization?.Org_name || el.Organization,
      }));
    },
  },
};
</script>
<style></style>
