<template>
  <div class="p-4">
    <trained-tasks></trained-tasks>
  </div>
</template>
<script>
import TrainedTasks from './TrainedTasks.vue';

export default {
  components: {
    TrainedTasks,
  },
};
</script>
